import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import nl from 'vuetify/es5/locale/nl';

import EmptyState from '@ntri/ntri-components/src/components/icons/EmptyState.vue';
import LangNL from '@ntri/ntri-components/src/components/icons/LangNL.vue';
import LangEN from '@ntri/ntri-components/src/components/icons/LangEN.vue';
import People from '@ntri/ntri-components/src/components/icons/IconPeople.vue';
import Visitors from '@ntri/ntri-components/src/components/icons/IconVisitors.vue';
import Organisation from '@ntri/ntri-components/src/components/icons/IconOrganisation.vue';
import Incidents from '@ntri/ntri-components/src/components/icons/IconIncidents.vue';
import Facebook from '@ntri/ntri-components/src/components/icons/IconFacebook.vue';
import LinkedIn from '@ntri/ntri-components/src/components/icons/IconLinkedIn.vue';
import Instagram from '@ntri/ntri-components/src/components/icons/IconInstagram.vue';
import Twitter from '@ntri/ntri-components/src/components/icons/IconTwitter.vue';
import error404 from '@ntri/ntri-components/src/components/icons/IconError404.vue';
import Availability from '@ntri/ntri-components/src/components/icons/IconAvailability.vue';
import Google from '@ntri/ntri-components/src/components/icons/IconGoogle.vue';
import Microsoft from '@ntri/ntri-components/src/components/icons/IconMicrosoft.vue';
import Start from '../components/icons/Start.vue';
import FloorPlan from '../components/icons/FloorPlan.vue';
import MasterCard from '../components/icons/stripe/MasterCard.vue';
import Amex from '../components/icons/stripe/Amex.vue';
import Discovery from '../components/icons/stripe/Discovery.vue';
import Ideal from '../components/icons/stripe/Ideal.vue';
import Visa from '../components/icons/stripe/Visa.vue';
import Assets from '../components/icons/Assets.vue';
import NoAssetGroups from '../components/icons/NoAssetGroups.vue';
import Reservations from '../components/icons/Reservations.vue';
import Celebration from '../components/icons/Celebration.vue';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#414FF5',
        secondary: '#F0F0F0',
      },
    },
  },
  lang: {
    locales: { nl },
    current: 'nl',
  },
  icons: {
    values: {
      people: {
        // name of our custom icon
        component: People, // our custom component
      },
      visitors: {
        component: Visitors,
      },
      organisation: {
        component: Organisation,
      },
      start: {
        component: Start,
      },
      incidents: {
        component: Incidents,
      },
      emptyState: {
        component: EmptyState,
      },
      celebration: {
        component: Celebration,
      },
      facebook: {
        component: Facebook,
      },
      linkedin: {
        component: LinkedIn,
      },
      instagram: {
        component: Instagram,
      },
      twitter: {
        component: Twitter,
      },
      error404: {
        component: error404,
      },
      reservations: {
        component: Reservations,
      },
      mastercard: {
        component: MasterCard,
      },
      amex: {
        component: Amex,
      },
      discovery: {
        component: Discovery,
      },
      ideal: {
        component: Ideal,
      },
      visa: {
        component: Visa,
      },
      lang_en: {
        component: LangEN,
      },
      lang_nl: {
        component: LangNL,
      },
      assets: {
        component: Assets,
      },
      noassetgroups: {
        component: NoAssetGroups,
      },
      availability: {
        component: Availability,
      },
      floorPlan: {
        component: FloorPlan,
      },
      google: {
        component: Google,
      },
      microsoft: {
        component: Microsoft,
      },
    },
  },
};

export default new Vuetify(opts);
