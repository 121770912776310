import { accountTypes } from '@ntri/ntri-components/src/helpers/accountTypes';
import store from '../store/index';

export default {
  path: '/:lang?/organisaties',
  component: () =>
    import(
      /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/organisations/OrganisationsView.vue'
    ),
  beforeEnter(to, from, next) {
    const isAllowed = (accountType) =>
      accountType &&
      (accountType === accountTypes.administrator ||
        accountType === accountTypes.hrManager ||
        accountType === accountTypes.facilityStaff ||
        accountType === accountTypes.facilityManager ||
        accountType === accountTypes.technicalDepartment);
    if (!store.getters.g_accountType) {
      store.watch(
        () => store.getters.g_accountType,
        (accountType) => {
          if (isAllowed(accountType)) {
            next();
          } else {
            next(false);
            console.info('Route denied');
          }
        }
      );
    } else if (isAllowed(store.getters.g_accountType)) {
      next();
    } else {
      next(false);
      console.info('Route denied');
    }
  },
  children: [
    {
      path: '/',
      name: 'Organisations',
      component: () =>
        import(
          /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/organisations/ListOrganisations'
        ),
      meta: { breadCrumb: 'Organisaties' },
    },
    {
      path: '/:lang?/organisaties/:id',
      component: () =>
        import(
          /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/organisations/OrganisationView.vue'
        ),
      children: [
        {
          path: '/',
          name: 'OrganisationInfo',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/organisations/OrganisationInfo.vue'
            ),
        },
        {
          path: '/:lang?/organisaties/:id/orgpersonen',
          name: 'PeopleChildOrg',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/people/ListPeople'
            ),
          meta: { breadCrumb: 'Personen' },
        },
        {
          path: '/:lang?/organisaties/:id/orgmeldingen',
          name: 'IncidentsChildOrg',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/incidents/ListIncidents'
            ),
          meta: { breadCrumb: 'Meldingen' },
        },
        {
          path: '/:lang?/organisaties/:id/orgbezoekers',
          name: 'VisitorsChildOrg',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/visitors/ListVisitors.vue'
            ),
          meta: { breadCrumb: 'Bezoekers' },
        },
        {
          path: '/:lang?/organisaties/:id/waarschuwingsadres',
          name: 'EmergencyInformation',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/organisations/EmergencyInfo.vue'
            ),
        },
        {
          path: '/:lang?/organisaties/:id/financieel',
          name: 'Financial',
          component: () =>
            import(
              /* webpackChunkName: "organisations" */ '@ntri/ntri-components/src/views/organisations/FinancialView.vue'
            ),
        },
      ],
    },
  ],
};
