import Vue from 'vue';
import { i18n } from 'vue-lang-router';
import { firestorePlugin } from 'vuefire';
import VueTelInputVuetify from 'vue-tel-input-vuetify';
import axios from 'axios';
import DialogDelete from '@ntri/ntri-components/src/components/dialogs/DialogDelete.vue';
import ListContainer from '@ntri/ntri-components/src/components/lists/ListContainer.vue';
import ListItem from '@ntri/ntri-components/src/components/lists/ListItem.vue';
import ListTitle from '@ntri/ntri-components/src/components/lists/ListTitle.vue';
import crudMixins from '@ntri/ntri-components/src/mixins/crud.mixin';
import logMixins from '@ntri/ntri-components/src/mixins/log.mixin';
import globalMixins from '@ntri/ntri-components/src/mixins/global.mixin';
import authMixins from '@ntri/ntri-components/src/mixins/auth.mixin';
import vuetify from '@/plugins/vuetify';
import 'moment/locale/nl';
import router from './router';
import App from './App.vue';
import store from './store';
import './firebase_config';
import './registerServiceWorker';

// axios
Vue.prototype.$http = axios;

// components
Vue.use(firestorePlugin);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.component('DialogDelete', DialogDelete);
Vue.component('ListContainer', ListContainer);
Vue.component('ListItem', ListItem);
Vue.component('ListTitle', ListTitle);

Vue.config.productionTip = false;

Vue.mixin({
  mixins: [logMixins, crudMixins, globalMixins, authMixins],
  computed: {
    accountTypes() {
      // This computed property contains the account types in ntri and is for example used when adding people. Which types are shown is based on settings
      const accountTypeSettings =
        this.$store.getters.g_organisation && this.$store.getters.g_organisation.settings
          ? this.$store.getters.g_organisation.settings.accountTypes
          : []; // see settings -> roles
      const accountTypeArray = [];

      // only admin's can add other admin's
      if (this.$store.getters.g_accountType.toLowerCase() === 'administrator') {
        accountTypeArray.push({
          text: this.$i18n.t('accountTypes.administrator'),
          value: 'administrator',
        });
      }

      if (
        this.$store.getters.g_accountType === 'hrManager' ||
        this.$store.getters.g_accountType.toLowerCase() === 'administrator'
      ) {
        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.hrManager').value
        ) {
          accountTypeArray.push({
            text: this.$i18n.t('accountTypes.hrManager'),
            value: 'hrManager',
          });
        }

        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.facilityManager').value
        ) {
          accountTypeArray.push({
            text: this.$i18n.t('accountTypes.facilityManager'),
            value: 'facilityManager',
          });
        }

        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.facilityStaff').value
        ) {
          accountTypeArray.push({
            text: this.$i18n.t('accountTypes.facilityStaff'),
            value: 'facilityStaff',
          });
        }

        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.technicalDepartment')
            .value
        ) {
          accountTypeArray.push({
            text: this.$i18n.t('accountTypes.technicalDepartment'),
            value: 'technicalDepartment',
          });
        }

        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.organisationManager')
            .value
        ) {
          accountTypeArray.push({
            text: this.$i18n.t('accountTypes.organisationManager'),
            value: 'organisationManager',
          });
        }

        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.employee').value
        ) {
          accountTypeArray.push({ text: this.$i18n.t('accountTypes.employee'), value: 'employee' });
        }
      }

      if (this.$store.getters.g_accountType === 'organisationManager') {
        if (
          accountTypeSettings.length &&
          accountTypeSettings.find((type) => type.label === 'accountTypes.employee').value
        ) {
          accountTypeArray.push({ text: this.$i18n.t('accountTypes.employee'), value: 'employee' });
        }
      }

      // Everyone can add visitors
      if (
        accountTypeSettings.length &&
        accountTypeSettings.find((type) => type.label === 'accountTypes.visitor').value
      ) {
        accountTypeArray.push({ text: this.$i18n.t('accountTypes.visitor'), value: 'visitor' });
      }
      return accountTypeArray;
    },
  },
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
