import { accountTypes } from '@ntri/ntri-components/src/helpers/accountTypes';
import store from '../store/index';

export default {
  path: '/:lang?/instellingen',
  redirect: '/instellingen/personen',
  name: 'Settings',
  meta: { breadCrumb: 'Instellingen' },
  component: () =>
    import(/* webpackChunkName: "settings" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
  children: [
    {
      path: '/:lang?/instellingen/rollen',
      name: 'Roles',
      meta: { breadCrumb: 'Rollen', settings: true },
      component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Roles.vue'),
    },
    {
      path: '/:lang?/instellingen/personen',
      name: 'PeopleSettings',
      meta: { breadCrumb: 'Personen', settings: true },
      component: () => import(/* webpackChunkName: "settings" */ '../views/settings/People.vue'),
      beforeEnter(to, from, next) {
        // nav guard, prevents user from accessing certain pages
        if (!store.getters.g_accountType) {
          store.watch(
            () => store.getters.g_accountType,
            (accountType) => {
              if (
                accountType &&
                (accountType === accountTypes.administrator ||
                  accountType === accountTypes.hrManager)
              ) {
                next();
              } else if (accountType === accountTypes.technicalDepartment) {
                next({ path: '/instellingen/assets' });
              } else if (accountType === accountTypes.facilityManager) {
                next({ path: '/instellingen/organisaties' });
              } else {
                next(false);
                console.info('Route denied');
              }
            }
          );
        } else if (
          store.getters.g_accountType &&
          (store.getters.g_accountType === accountTypes.administrator ||
            store.getters.g_accountType === accountTypes.hrManager)
        ) {
          next();
        } else if (store.getters.g_accountType === accountTypes.technicalDepartment) {
          next({ path: '/instellingen/assets' });
        } else if (store.getters.g_accountType === accountTypes.facilityManager) {
          next({ path: '/instellingen/organisaties' });
        } else {
          next(false);
          console.info('Route denied');
        }
      },
    },
    {
      path: '/:lang?/instellingen/organisaties',
      name: 'OrgSettings',
      meta: { breadCrumb: 'Organisaties', settings: true },
      component: () =>
        import(/* webpackChunkName: "settings" */ '../views/settings/Organisations.vue'),
      beforeEnter(to, from, next) {
        // nav guard, prevents user from accessing certain pages
        if (!store.getters.g_accountType) {
          store.watch(
            () => store.getters.g_accountType,
            (accountType) => {
              if (
                accountType &&
                (accountType === accountTypes.administrator ||
                  accountType === accountTypes.facilityManager)
              ) {
                next();
              } else if (accountType === accountTypes.technicalDepartment) {
                next({ path: '/instellingen/assets' });
              } else if (accountType === accountTypes.hrManager) {
                next({ path: '/instellingen/personen' });
              } else {
                next(false);
                console.info('Route denied');
              }
            }
          );
        } else if (
          store.getters.g_accountType &&
          (store.getters.g_accountType === accountTypes.administrator ||
            store.getters.g_accountType === accountTypes.facilityManager)
        ) {
          next();
        } else if (
          store.getters.g_accountType &&
          store.getters.g_accountType === accountTypes.technicalDepartment
        ) {
          next({ path: '/instellingen/assets' });
        } else if (store.getters.g_accountType === accountTypes.hrManager) {
          next({ path: '/instellingen/personen' });
        } else {
          next(false);
          console.info('Route denied');
        }
      },
    },
    {
      path: '/:lang?/instellingen/bezoekers',
      meta: { breadCrumb: 'Bezoekers', settings: true },
      component: () =>
        import(
          /* webpackChunkName: "settings" */ '../views/settings/visitors/VisitorsParentView.vue'
        ),
      beforeEnter(to, from, next) {
        // nav guard, prevents user from accessing certain pages
        if (!store.getters.g_accountType) {
          store.watch(
            () => store.getters.g_accountType,
            (accountType) => {
              if (
                accountType &&
                (accountType === accountTypes.administrator ||
                  accountType === accountTypes.facilityManager)
              ) {
                next();
              } else if (accountType === accountTypes.technicalDepartment) {
                next({ path: '/instellingen/assets' });
              } else if (accountType === accountTypes.hrManager) {
                next({ path: '/instellingen/personen' });
              } else {
                next(false);
                console.info('Route denied');
              }
            }
          );
        } else if (
          store.getters.g_accountType &&
          (store.getters.g_accountType === accountTypes.administrator ||
            store.getters.g_accountType === accountTypes.facilityManager)
        ) {
          next();
        } else if (
          store.getters.g_accountType &&
          store.getters.g_accountType === accountTypes.technicalDepartment
        ) {
          next({ path: '/instellingen/assets' });
        } else if (store.getters.g_accountType === accountTypes.hrManager) {
          next({ path: '/instellingen/personen' });
        } else {
          next(false);
          console.info('Route denied');
        }
      },
      children: [
        {
          path: '/',
          name: 'VisitorSettings',
          meta: { settings: true },
          component: () =>
            import(
              /* webpackChunkName: "settings" */ '../views/settings/visitors/VisitorsView.vue'
            ),
        },
        {
          path: '/:lang?/instellingen/bezoekers/zelf-regelen',
          name: 'SelfServiceSettings',
          meta: { settings: true },
          component: () =>
            import(
              /* webpackChunkName: "settings" */ '../views/settings/visitors/SelfServiceView.vue'
            ),
        },
      ],
    },
    {
      path: '/:lang?/instellingen/meldingen',
      name: 'IncidentSettings',
      meta: { breadCrumb: 'Meldingen', settings: true },
      component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Incidents.vue'),
      beforeEnter(to, from, next) {
        if (!store.getters.g_accountType) {
          store.watch(
            () => store.getters.g_accountType,
            (accountType) => {
              if (
                accountType &&
                (accountType === accountTypes.administrator ||
                  accountType === accountTypes.facilityManager)
              ) {
                next();
              } else if (accountType === accountTypes.technicalDepartment) {
                next({ path: '/instellingen/assets' });
              } else if (accountType === accountTypes.hrManager) {
                next({ path: '/instellingen/personen' });
              } else {
                next(false);
                console.info('Route denied');
              }
            }
          );
        } else if (
          store.getters.g_accountType &&
          (store.getters.g_accountType === accountTypes.administrator ||
            store.getters.g_accountType === accountTypes.facilityManager)
        ) {
          next();
        } else if (
          store.getters.g_accountType &&
          store.getters.g_accountType === accountTypes.technicalDepartment
        ) {
          next({ path: '/instellingen/assets' });
        } else if (store.getters.g_accountType === accountTypes.hrManager) {
          next({ path: '/instellingen/personen' });
        } else {
          next(false);
          console.info('Route denied');
        }
      },
    },
    {
      path: '/:lang?/instellingen/globale-instellingen',
      name: 'GlobalSettings',
      meta: { breadCrumb: 'Globale instellingen', settings: true },
      component: () => import(/* webpackChunkName: "settings" */ '../views/settings/Global.vue'),
    },
  ],
};
