import { accountTypes } from '@ntri/ntri-components/src/helpers/accountTypes';
import store from '../store/index';

export default {
  path: '/:lang?/bezoekers',
  component: () =>
    import(/* webpackChunkName: "visitors" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
  children: [
    {
      path: '/',
      name: 'Visitors',
      meta: { breadCrumb: 'Bezoekers' },
      component: () =>
        import(
          /* webpackChunkName: "visitors" */ '@ntri/ntri-components/src/views/visitors/ListVisitors.vue'
        ),
      beforeEnter(to, from, next) {
        if (!store.getters.g_accountType) {
          store.watch(
            () => store.getters.g_accountType,
            (accountType) => {
              if (accountType && accountType === 'visitor') {
                next(`/bezoeker/${store.getters.g_userId}`);
                console.info('Route denied');
              } else {
                next();
              }
            }
          );
        } else if (store.getters.g_accountType && store.getters.g_accountType === 'visitor') {
          next(`/bezoeker/${store.getters.g_userId}`);
          console.info('Route denied');
        } else {
          next();
        }
      },
    },
    {
      path: '/:lang?/bezoekers/zelf-regelen',
      name: 'Visits',

      component: () => import(/* webpackChunkName: "visits" */ '../views/visits/ListVisits'),

      beforeEnter(to, from, next) {
        const isAllowed = (accountType) => (accountType && [
          accountTypes.administrator,
          accountTypes.facilityManager,
          accountTypes.facilityStaff,
        ].includes(accountType));

        if (!store.getters.g_accountType) {
          store.watch(() => store.getters.g_accountType, (accountType) => {
            if (isAllowed(accountType)) {
              next();
            } else {
              next(false);
              console.info('Route denied');
            }
          });
        } else if (isAllowed(store.getters.g_accountType)) {
          next();
        } else {
          next(false);
          console.info('Route denied');
        }
      },
    },
    {
      path: '/:lang?/bezoekers/zelf-regelen/:id',
      name: 'Visit',
      component: () => import(/* webpackChunkName: "visit" */ '../views/visits/VisitView.vue'),
    },
    {
      path: '/:lang?/bezoekers/:id',
      name: 'Appointment',
      component: () =>
        import(/* webpackChunkName: "visitors" */ '../views/visitors/Appointment.vue'),
    },
  ],
};
