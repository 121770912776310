<template>
  <v-app id="app">
    <main-header
      v-if="$store.getters.g_userLoggedIn || $route.meta.signup === true"
      :displayName="currentUser.displayName"
      :email="currentUser.email ? currentUser.email.accountEmail : ''"
      :profileLabel="$t('global.profile')"
      :feedbackLabel="$t('global.feedback')"
      :logoutLabel="$t('labels.logout')"
      @sign-out="signOut"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="163.5"
        height="24"
        viewBox="0 0 163.5 24"
      >
        <defs>
          <linearGradient
            id="a"
            x1="1.386"
            y1="0.668"
            x2="0.556"
            y2="0.798"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.118" stop-color="#414fef" />
            <stop offset="1" stop-color="#417eef" />
          </linearGradient>
        </defs>
        <path
          class="a"
          d="M21,6.9h2.7l.2,1.4a3.421,3.421,0,0,1,3-1.6c3-.1,6.1,1.4,6.1,5.8v5.9H29.8v-6c0-2.3-1.4-3.1-2.6-3.1a2.854,2.854,0,0,0-3,3.1v6H21V6.9Z"
        />
        <path
          class="a"
          d="M35.3,6.9h2.2V1.4h3.1V6.8h4.2V9.2H40.6v4.3c0,1.9,1.8,3.1,4,2l.8,2.3a5.734,5.734,0,0,1-2.8.8c-3.3,0-5-2.2-5-5.1V9.3H35.4l-.1-2.4Z"
        />
        <path
          class="a"
          d="M50.6,6.9l.2,1.4a3.525,3.525,0,0,1,3-1.7,4.991,4.991,0,0,1,3.6,1.5L56,10.2a3.176,3.176,0,0,0-2-.8,2.981,2.981,0,0,0-3,2.9v6.2H48V6.9Z"
        />
        <path class="a" d="M60.2,5.2V1.4h3.1V5.2Zm0,13.3V6.9h3.1V18.5Z" />
        <path
          class="a"
          d="M72.2,8V6.4h8.4V8H72.2Zm2.4,10.5V5a6.873,6.873,0,0,1,.3-2.2,3.587,3.587,0,0,1,1-1.4A2.666,2.666,0,0,1,77.3.7,4.954,4.954,0,0,1,79,.5a3.085,3.085,0,0,1,.9.1,2.2,2.2,0,0,0,.8.1c.2.1.5.1.6.2l-.2,1.8a2.353,2.353,0,0,0-.8-.2,2.787,2.787,0,0,0-1-.1,2.541,2.541,0,0,0-2.1.7A2.787,2.787,0,0,0,76.4,5V6.5l.1.5V18.6H74.6Z"
        />
        <path
          class="a"
          d="M86,18.7a3.83,3.83,0,0,1-1.9-.5A2.19,2.19,0,0,1,82.8,17a3.453,3.453,0,0,1-.5-1.8,4.671,4.671,0,0,1,.3-1.7,2.66,2.66,0,0,1,1-1.1,5.171,5.171,0,0,1,1.3-.6c.5-.2,1-.2,1.4-.4l3.8-.6A3.078,3.078,0,0,0,89.8,9a1.329,1.329,0,0,0-1-.8A3.887,3.887,0,0,0,87.3,8a3.085,3.085,0,0,0-.9.1c-.3.1-.6.1-1,.2-.3.1-.7.2-1.1.3a3.08,3.08,0,0,0-1.2.5L82.9,7a6.214,6.214,0,0,1,1-.3l1.2-.3a5.853,5.853,0,0,1,1.3-.2,6.6,6.6,0,0,1,1.4-.1,5.214,5.214,0,0,1,2.3.5A2.876,2.876,0,0,1,91.6,8a5.283,5.283,0,0,1,.6,2.4v8.1H90.4l-.1-1.7h-.2a9.322,9.322,0,0,1-1,1,4.58,4.58,0,0,1-1.4.7A9.852,9.852,0,0,1,86,18.7Zm.6-1.7a6.753,6.753,0,0,0,1.4-.2,5.025,5.025,0,0,0,1.2-.8,3.881,3.881,0,0,0,1-1.4V12a.764.764,0,0,1-.5.2,2.509,2.509,0,0,1-1,.2,16.449,16.449,0,0,1-1.8.3,3.374,3.374,0,0,0-1.2.4,2.047,2.047,0,0,0-.8.8,2.016,2.016,0,0,0-.3,1.1,1.755,1.755,0,0,0,.6,1.5A1.624,1.624,0,0,0,86.6,17Z"
        />
        <path
          class="a"
          d="M100.4,18.7a6.129,6.129,0,0,1-3.4-.8,6.249,6.249,0,0,1-2.1-2.2,6.971,6.971,0,0,1-.7-3.3,7.245,7.245,0,0,1,.7-3.3,4.776,4.776,0,0,1,2-2.2,5.785,5.785,0,0,1,3.3-.8c.5,0,1,.1,1.4.1.5.1.9.2,1.4.3a2.22,2.22,0,0,1,1.1.5l-.2,1.8a13.919,13.919,0,0,0-1.3-.6,5.16,5.16,0,0,0-1.1-.2c-.3,0-.6-.1-1-.1a4.07,4.07,0,0,0-3,1.2,4.411,4.411,0,0,0-1.1,3.3,5.214,5.214,0,0,0,.5,2.3,3.53,3.53,0,0,0,1.5,1.7,4.626,4.626,0,0,0,2.5.6c.3,0,.7-.1,1-.1a3.553,3.553,0,0,1,1-.2,1.865,1.865,0,0,0,1-.5l.2,1.8c-.2.2-.6.2-1,.4a5.019,5.019,0,0,1-1.2.2A7.083,7.083,0,0,1,100.4,18.7Z"
        />
        <path
          class="a"
          d="M108.2,3.6a1.366,1.366,0,0,1-1.1-.4,1.626,1.626,0,0,1-.5-1.1,1.29,1.29,0,0,1,.5-1.1,1.6,1.6,0,0,1,1.1-.4,1.366,1.366,0,0,1,1.1.4,1.6,1.6,0,0,1,.4,1.1,1.246,1.246,0,0,1-.4,1.1A1.82,1.82,0,0,1,108.2,3.6Zm-.9,14.9V6.4l2-.1V18.4h-2Z"
        />
        <path class="a" d="M113.4,18.5V.7l2-.2V18.6l-2-.1Z" />
        <path
          class="a"
          d="M120.7,3.6a1.366,1.366,0,0,1-1.1-.4,1.626,1.626,0,0,1-.5-1.1,1.29,1.29,0,0,1,.5-1.1,1.6,1.6,0,0,1,1.1-.4,1.366,1.366,0,0,1,1.1.4,1.6,1.6,0,0,1,.4,1.1,1.246,1.246,0,0,1-.4,1.1A2.1,2.1,0,0,1,120.7,3.6Zm-1,14.9V6.4l2-.1V18.4h-2Z"
        />
        <path
          class="a"
          d="M124.5,8V6.4h8.6V8h-8.6Zm6.1,10.8a3.939,3.939,0,0,1-2-.5,2.367,2.367,0,0,1-1.3-1.3,7.116,7.116,0,0,1-.5-2.2V2.5l2-.5V14.4a2.986,2.986,0,0,0,.6,2,2,2,0,0,0,1.7.6,5.755,5.755,0,0,0,1-.2,2.355,2.355,0,0,0,1-.5l.2,1.8-.6.3a2.509,2.509,0,0,1-1,.2A4.253,4.253,0,0,1,130.6,18.8Z"
        />
        <path
          class="a"
          d="M137,3.6a1.366,1.366,0,0,1-1.1-.4,1.626,1.626,0,0,1-.5-1.1,1.29,1.29,0,0,1,.5-1.1A1.6,1.6,0,0,1,137,.6a1.366,1.366,0,0,1,1.1.4,1.6,1.6,0,0,1,.4,1.1,1.246,1.246,0,0,1-.4,1.1A2.1,2.1,0,0,1,137,3.6Zm-1,14.9V6.4l2-.1V18.4h-2Z"
        />
        <path
          class="a"
          d="M147.7,18.8a6.849,6.849,0,0,1-3.6-.8,5.474,5.474,0,0,1-2.1-2.2,8.109,8.109,0,0,1-.6-3.3,8.808,8.808,0,0,1,.6-3.3A4.588,4.588,0,0,1,143.9,7a5.571,5.571,0,0,1,3-.8,5.26,5.26,0,0,1,2.6.6,3.72,3.72,0,0,1,1.7,1.8,6.235,6.235,0,0,1,.6,2.9v.8c0,.2-.1.5-.1.7l-1.8-.9a.756.756,0,0,1,.1-.4v-.3a3.2,3.2,0,0,0-.9-2.6,3.213,3.213,0,0,0-4.1-.3,3.738,3.738,0,0,0-1.3,1.5,5.091,5.091,0,0,0-.5,2.2v.2a5.356,5.356,0,0,0,.5,2.4,3.814,3.814,0,0,0,1.5,1.6,5,5,0,0,0,2.6.6c.4,0,.8-.1,1.2-.1a5.853,5.853,0,0,1,1.3-.2,2.567,2.567,0,0,0,1.2-.5l.1,1.8a5.821,5.821,0,0,1-1.1.5,5.9,5.9,0,0,1-1.4.2C148.7,18.7,148.2,18.8,147.7,18.8Zm-5.5-6V11.4l8.4-.1,1.2.5v1Z"
        />
        <path
          class="a"
          d="M158.6,18.8c-.5,0-1-.1-1.5-.1-.5-.1-1-.2-1.4-.3a1.881,1.881,0,0,1-1.1-.5l.2-1.8,1.2.6c.4.2.9.2,1.4.4a7.084,7.084,0,0,0,1.4.1,5.067,5.067,0,0,0,1.3-.2,1.332,1.332,0,0,0,.9-.6,1.737,1.737,0,0,0,.3-1.2,1.884,1.884,0,0,0-.2-.8,1.9,1.9,0,0,0-1-.6c-.5-.1-1.1-.4-1.8-.6-.6-.2-1.2-.4-1.7-.6a2.94,2.94,0,0,1-1.2-1,4.248,4.248,0,0,1-.5-1.7A3.315,3.315,0,0,1,156,7.3a4.414,4.414,0,0,1,2.9-1c.5,0,1,.1,1.5.1.5.1.9.2,1.4.3.4.2.8.2,1,.5L162.4,9a6.721,6.721,0,0,0-1.2-.6,6.985,6.985,0,0,0-1.2-.3,3.751,3.751,0,0,0-1-.1,4.4,4.4,0,0,0-1,.2,1.374,1.374,0,0,0-.8.5,1.493,1.493,0,0,0-.3,1,1.884,1.884,0,0,0,.2.8,2.407,2.407,0,0,0,.8.6,13.779,13.779,0,0,0,1.6.6c.8.2,1.5.5,2.1.7a3.587,3.587,0,0,1,1.4,1,2.86,2.86,0,0,1,.5,1.7,3.2,3.2,0,0,1-.5,1.8,3.151,3.151,0,0,1-1.4,1.4A11.728,11.728,0,0,1,158.6,18.8Z"
        />
        <path class="b" d="M11.4,24,1.8,18.8V5.2L11.4,0Z" />
        <path class="c" d="M2,2.8,0,4V6.9L2,8,4.2,6.9V4Z" />
        <path class="a" d="M5.4,13.7l-2.1,1v3.1l2.1,1,2-1V14.7Z" />
        <path class="d" d="M11.4,14.6,9.3,13.4V10.5l2.1-1.1Z" />
      </svg>
    </main-header>

    <main-nav
      v-if="$store.getters.g_userLoggedIn"
      :displayName="currentUser.displayName"
      :email="currentUser.email ? currentUser.email.accountEmail : ''"
      :profilePicture="currentUser.profilePictureUrlx80"
      :class="{ onboarding__active: onboardingDialog }"
      :items="navItems()"
      :back="$route.meta.personalSettings || $route.meta.settings"
    />

    <v-main>
      <v-container :key="resetKey" fluid>
        <router-view v-if="showViewWhenReady" />
      </v-container>
    </v-main>

    <v-dialog
      v-model="onboardingDialog"
      max-width="360"
      content-class="onboarding"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
      @click:outside="setLocalOnboardingValue()"
    >
      <v-window v-model="onboardingSlide">
        <v-window-item v-for="item in onboardingItems" :key="`card-${item.title}`">
          <v-card flat>
            <v-icon :class="item.icon">${{ item.icon }}</v-icon>

            <v-card-title>
              <h1>{{ item.title }}</h1>
            </v-card-title>
            <v-card-text>
              <p>{{ item.text }}</p>
            </v-card-text>
            <v-card-actions>
              <v-item-group v-model="onboardingSlide" mandatory>
                <v-item
                  v-for="item in onboardingItems"
                  :key="`btn-${item.title}`"
                  v-slot:default="{ active, toggle }"
                >
                  <v-btn :input-value="active" icon @click="toggle">
                    <v-icon>fiber_manual_record</v-icon>
                  </v-btn>
                </v-item>
              </v-item-group>
              <div class="buttons">
                <v-btn
                  @click="
                    onboardingDialog = false;
                    setLocalOnboardingValue();
                  "
                  depressed
                >
                  {{ $t('labels.skip') }}
                </v-btn>
                <v-btn
                  v-if="onboardingItems.length - 1 !== onboardingSlide"
                  @click="next"
                  depressed
                  class="primary"
                >
                  {{ item.buttonText }}
                </v-btn>
                <v-btn
                  v-if="onboardingItems.length - 1 === onboardingSlide"
                  @click="
                    onboardingDialog = false;
                    onboardingTooltip = true;
                    setLocalOnboardingValue();
                  "
                  depressed
                  class="primary"
                >
                  {{ item.buttonText }}
                </v-btn>
              </div>
            </v-card-actions>
          </v-card>
        </v-window-item>
      </v-window>
    </v-dialog>

    <div
      @click="onboardingTooltip = false"
      v-if="onboardingTooltip && onboardingDialog"
      class="onboarding__tooltip"
    >
      <span v-if="onboardingTooltip && onboardingDialog">{{ $t('global.onboardingTooltip') }}</span>
    </div>

    <sw-update-snackbar />

    <asset-cache-snackbar v-if="$store.getters.g_orgRef" />

    <alert v-if="showAlert" :message="alertMessage" />
  </v-app>
</template>

<script>
import { collection, addDoc } from 'firebase/firestore';
import { getToken } from 'firebase/messaging';
import { onIdTokenChanged } from 'firebase/auth';
import authMixin from '@ntri/ntri-components/src/mixins/auth.mixin';
import MainHeader from '@ntri/ntri-components/src/components/MainHeader.vue';
import MainNav from '@ntri/ntri-components/src/components/nav/MainNav.vue';
import SwUpdateSnackbar from '@ntri/ntri-components/src/components/snackbars/SwUpdateSnackbar.vue';
import AssetCacheSnackbar from '@ntri/ntri-components/src/components/snackbars/AssetCacheSnackbar.vue';
import Alert from '@ntri/ntri-components/src/components/AlertMessage.vue';
import FirebaseHelper from '@ntri/ntri-components/src/firebase_config';
import { accountTypes } from '@ntri/ntri-components/src/helpers/accountTypes';

export default {
  components: {
    MainNav,
    MainHeader,
    Alert,
    SwUpdateSnackbar,
    AssetCacheSnackbar,
  },
  data() {
    return {
      onboardingTooltip: false,
      onboardingDialog: false,
      onboardingSlide: 0,
      resetKey: 0,
      isOnLine: navigator.onLine,
      deviceTokens: [],
    };
  },
  mixins: [authMixin],
  mounted() {
    this.consoleText();
    // this function triggers on login or refresh token changes
    onIdTokenChanged(FirebaseHelper.getFirebaseAuth(), (user) => {
      if (user) {
        user.getIdTokenResult().then((tokenResult) => {
          if (!tokenResult) {
            this.signOut();
          } else if (
            tokenResult.claims.accountType &&
            tokenResult.claims.orgId &&
            tokenResult.claims.docId
          ) {
            this.userHasAccess(tokenResult, user);
          }
        });
      }
    });

    // initial value
    this.$store.commit('online', navigator.onLine);
    this.listenIfAppIsOnline('online');
  },
  beforeDestroy() {
    window.removeEventListener('online', () => {});
    window.removeEventListener('offline', () => {});
  },
  methods: {
    getDeviceTokens(orgId, docId) {
      const deviceTokens = FirebaseHelper.getDb()
        .collection(`organisations/${orgId}/people/${docId}/clientDevices`)
        .where('userDocId', '==', docId);
      this.$bind('deviceTokens', deviceTokens)
        .then(() => {
          this.getNotificationToken(this.deviceTokens, docId);
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    getNotificationToken(existingTokens, userDocId) {
      getToken(this.$messaging, { vapidKey: process.env.VUE_APP_FIREBASE_MESSAGING_VAPID_KEY })
        .then((currentToken) => {
          if (currentToken) {
            if (existingTokens.filter((token) => token.fcmToken === currentToken).length === 0) {
              this.saveDeviceToken(currentToken, userDocId);
            }
          } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            Notification.requestPermission();
          }
        })
        .catch((err) => {
          this.logError('An error occurred while retrieving token. ', false, err);
        });
    },
    saveDeviceToken(deviceToken, userDocId) {
      addDoc(
        collection(
          FirebaseHelper.getDb(),
          `organisations/${this.$store.getters.g_orgRef}/people/${this.$store.getters.g_userId}/clientDevices`
        ),
        {
          timestamp: new Date(),
          fcmToken: deviceToken,
          userDocId,
        }
      )
        .then(() => {
          this.logSuccess('Saved token to user document');
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    setLocalOnboardingValue() {
      localStorage.onboarding = false;
    },
    next() {
      // function to change slides in the onboarding dialog
      this.onboardingSlide =
        this.onboardingSlide + 1 === this.onboardingItems.length ? 0 : this.onboardingSlide + 1;
    },
    firstSignInCheck(user) {
      if (
        user.metadata.creationTime === user.metadata.lastSignInTime &&
        (!localStorage.onboarding || localStorage.onboarding === true)
      ) {
        this.onboardingDialog = true;
      }
    },
    consoleText() {
      const text = `
                  __       _
           ____  / /______(_)
          / __  / __/ ___/ /
         / / / / /_/ /  / /
        /_/ /_/|__/_/  /_/
      `;
      console.log(`%c ${text}`, 'color: #bada55');
    },
    reservationRejected() {
      const orgId = this.$store.getters.g_orgRef;
      const entityId = this.$store.getters.g_entityId;

      if (!orgId) {
        setTimeout(this.reservationRejected, 100);
        return;
      }
      if (!entityId) {
        setTimeout(this.reservationRejected, 100);
        return;
      }

      FirebaseHelper.getDb()
        .collection('organisations')
        .doc(orgId)
        .collection('reservationsRejected')
        .where('seen', '==', false)
        .where('reservation.inviteeIds', 'array-contains', entityId)
        .onSnapshot((docs) => {
          docs.forEach((docRef) => {
            this.logError(
              'Je reservering is gecanceld',
              true,
              this.$t('reservationDashboardPage.reservation_rejected_error').toString()
            );

            FirebaseHelper.getDb()
              .collection('organisations')
              .doc(orgId)
              .collection('reservationsRejected')
              .doc(docRef.id)
              .set({ seen: true }, { merge: true });
          });
        });
    },
    async userHasAccess(idTokenResult, user) {
      this.$store.commit('userLogin');
      this.firstSignInCheck(user);

      // store accountType so we can access it for navigation guards
      this.$store.commit('accountType', idTokenResult.claims.accountType);
      this.$store.commit('orgRef', idTokenResult.claims.orgId);
      this.$store.commit('userEmail', idTokenResult.claims.email);
      this.$store.commit('userId', idTokenResult.claims.docId);
      this.$store.dispatch('bindOrganisation', { id: idTokenResult.claims.orgId });
      this.$store.dispatch('bindPerson', {
        docId: idTokenResult.claims.docId,
        orgId: idTokenResult.claims.orgId,
      });

      // get notifcation token / ask for permission
      this.getDeviceTokens(idTokenResult.claims.orgId, idTokenResult.claims.docId);

      const entities = await FirebaseHelper.getDb()
        .collection('organisations')
        .doc(this.$store.getters.g_orgRef)
        .collection('entities')
        .where('type', '==', 'person')
        .where('data.id', '==', this.$store.getters.g_userId)
        .get();

      // There should only be one so we can do this
      entities.forEach((docRef) => {
        this.$store.commit('entity', { ...docRef.data(), id: docRef.id });
        this.$store.commit('entityId', docRef.id);
      });

      // get user info
      console.log('Getting user info');
      const personDocumentReference = await FirebaseHelper.getDb()
        .doc(`organisations/${idTokenResult.claims.orgId}/people/${idTokenResult.claims.docId}`)
        .get();
      const personDocument = personDocumentReference.data();

      if (personDocument) {
        this.logSuccess('Got user info');

        if (this.currentUser.state.toLowerCase() === 'active') {
          this.$store.commit('userName', this.currentUser.displayName);
          this.logSuccess('Successfull login');
          this.changeLocale();
          this.reservationRejected();
        } else {
          // sign-out user immediately if they are inactive or soft-deleted
          console.log('User is either inactive or deleted and has been automatically signed out.');
          this.signOut();
        }
      }
    },
    navItemsForPersonSettings(accountType) {
      if (accountType && accountType !== accountTypes.visitor) {
        return [
          { title: 'global.profile', icon: 'person', link: '/persoonlijke-instellingen/profiel' },
          {
            title: 'global.account',
            icon: 'settings',
            link: '/persoonlijke-instellingen/account-instellingen',
          },
        ];
      }

      return [];
    },
    navItemsForSettings(accountType) {
      const navArray = [];
      if (accountType === accountTypes.administrator) {
        navArray.push(
          { title: 'navSettings.userManagement', type: 'title' },
          { title: 'rolesPage.title', icon: 'lock', link: '/instellingen/rollen' }
        );
      }

      if (
        accountType === accountTypes.administrator ||
        accountType === accountTypes.hrManager ||
        accountType === accountType.facilityManager
      ) {
        navArray.push({ title: 'navSettings.appSettings', type: 'title' });
      }

      if (accountType === accountTypes.administrator || accountType === accountTypes.hrManager) {
        navArray.push({
          title: 'peoplePage.title',
          icon: 'people',
          link: '/instellingen/personen',
        });
      }

      if (
        accountType === accountTypes.administrator ||
        accountType === accountTypes.facilityManager
      ) {
        navArray.push(
          {
            title: 'organisationsPage.title',
            icon: 'business_center',
            link: '/instellingen/organisaties',
          },
          {
            title: 'visitorsPage.title',
            icon: 'perm_contact_calendar',
            link: '/instellingen/bezoekers',
          },
          { title: 'incidentsPage.title', icon: 'report_problem', link: '/instellingen/meldingen' }
        );
      }

      if (accountType === accountTypes.administrator) {
        navArray.push({
          title: 'infoPage.title',
          icon: 'info',
          link: '/instellingen/info-instellingen',
        });
      }

      if (accountType === accountTypes.administrator) {
        navArray.push(
          { title: 'navSettings.global', type: 'title' },
          {
            title: 'navSettings.global',
            icon: 'settings',
            link: '/instellingen/globale-instellingen',
          }
        );
      }
      return navArray;
    },
    navItemsForMainPage(accountType) {
      const navArray = [];
      navArray[3] = {
        title: 'incidentsPage.title',
        icon: 'report_problem',
        link: '/meldingen',
      };

      navArray[6] = {
        title: 'infoPage.title',
        icon: 'info',
        link: '/info',
        disabled: !this.appIsOnline,
      };

      if (
        accountType === accountTypes.hrManager ||
        accountType === accountTypes.facilityManager ||
        accountType === accountTypes.technicalDepartment ||
        accountType === accountTypes.administrator ||
        accountType === accountTypes.employee ||
        accountType === accountTypes.facilityStaff ||
        accountType === accountTypes.organisationManager
      ) {
        navArray[0] = {
          title: 'peoplePage.title',
          icon: 'people',
          link: '/personen',
          disabled: !this.appIsOnline,
        };

        if (
          accountType !== accountTypes.employee &&
          accountType !== accountTypes.organisationManager
        ) {
          navArray[1] = {
            title: 'organisationsPage.title',
            icon: 'business_center',
            link: '/organisaties',
            disabled: !this.appIsOnline,
          };
        }

        if (accountType === accountTypes.employee) {
          navArray[2] = {
            title: 'visitorsPage.title',
            icon: 'perm_contact_calendar',
            link: '/bezoekers',
            disabled: !this.appIsOnline,
          };
        } else {
          navArray[2] = {
            title: 'visitorsPage.title',
            icon: 'perm_contact_calendar',
            link: '/bezoekers',
            disabled: !this.appIsOnline,
            sublinks: [
              {
                title: 'visitsPage.title',
                link: '/zelf-regelen',
              },
            ],
          };
        }

        if (
          accountType === accountTypes.administrator ||
          accountType === accountTypes.organisationManager
        ) {
          navArray[4] = {
            title: 'reportsPage.title',
            icon: 'insert_chart',
            link: '/rapportages',
            disabled: !this.appIsOnline,
          };
        }

        if (
          accountType !== accountTypes.employee &&
          accountType !== accountTypes.facilityStaff &&
          accountType !== accountTypes.technicalDepartment &&
          accountType !== accountTypes.organisationManager
        ) {
          navArray[7] = {
            title: 'global.settings',
            icon: 'settings',
            link: '/instellingen/personen?holdNav=true',
            disabled: !this.appIsOnline,
          };
        }
      }

      return navArray.filter((element) => element);
    },
    navItems() {
      const accountType = this.$store.getters.g_accountType;
      const { meta } = this.$route;

      if (accountType) {
        if (meta.personalSettings && accountType !== accountTypes.visitor) {
          return this.navItemsForPersonSettings(accountType);
        }

        if (meta.settings) {
          return this.navItemsForSettings(accountType);
        }

        return this.navItemsForMainPage(accountType);
      }
      return [];
    },
  },
  computed: {
    onboardingItems() {
      return [
        {
          icon: 'start',
          title: this.$i18n.t('onboarding.step1.title'),
          text: this.$i18n.t('onboarding.step1.text'),
          buttonText: this.$i18n.t('onboarding.step1.buttonText'),
        },
        {
          icon: 'organisation',
          title: this.$i18n.t('onboarding.step2.title'),
          text: this.$i18n.t('onboarding.step2.text'),
          buttonText: this.$i18n.t('labels.next'),
        },
        {
          icon: 'people',
          title: this.$i18n.t('onboarding.step3.title'),
          text: this.$i18n.t('onboarding.step3.text'),
          buttonText: this.$i18n.t('labels.next'),
        },
        {
          icon: 'visitors',
          title: this.$i18n.t('onboarding.step4.title'),
          text: this.$i18n.t('onboarding.step4.text'),
          buttonText: this.$i18n.t('labels.next'),
        },
        {
          icon: 'incidents',
          title: this.$i18n.t('onboarding.step5.title'),
          text: this.$i18n.t('onboarding.step5.text'),
          buttonText: this.$i18n.t('onboarding.step5.buttonText'),
        },
      ];
    },
    showAlert() {
      return this.$store.getters.g_showAlert;
    },
    alertMessage() {
      return this.$store.getters.g_alertMessage;
    },
    accountType() {
      return this.$store.getters.g_accountType;
    },
    showViewWhenReady() {
      return (
        this.$route.name === 'Login' ||
        (this.$store.getters.g_person !== undefined &&
          this.$store.getters.g_organisation !== undefined)
      );
    },
    appIsOnline() {
      return this.$store.getters.g_online;
    },
    currentUser() {
      if (this.$store.getters.g_person) {
        return this.$store.getters.g_person;
      }
      return {};
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        // change the page title based on current page
        switch (this.$route.name) {
          case undefined:
            document.title = 'ntri facilities - 404';
            break;
          case null:
            document.title = 'ntri facilities';
            break;
          case 'Incident':
          case 'Person':
          case 'Organisation':
          case 'Appointment':
          case 'Visit':
            document.title = `ntri facilities - ${this.$i18n.t(
              `labels.${this.$route.name.toLowerCase()}`
            )} - ${this.$route.params.id}`;
            break;
          case 'IncidentsChildOrg':
            document.title = `ntri facilities - ${this.$i18n.t('incidentsPage.title')}`;
            break;
          case 'VisitorsChildOrg':
            document.title = `ntri facilities - ${this.$i18n.t('visitorsPage.title')}`;
            break;
          case 'PeopleChildOrg':
            document.title = `ntri facilities - ${this.$i18n.t('peoplePage.title')}`;
            break;
          case 'Login':
            document.title = `ntri facilities - ${this.$i18n.t(
              `labels.${this.$route.name.toLowerCase()}`
            )}`;
            break;
          case 'Profile':
            document.title = `ntri facilities - ${this.$i18n.t(
              `global.${this.$route.name.toLowerCase()}`
            )}`;
            break;
          case 'InfoCategory':
          case 'InfoSettingsCategory':
            document.title = `ntri facilities - ${this.$i18n.t('infoPage.title')} - ${
              this.$route.params.id
            }`;
            break;
          default:
            document.title = this.$route.name
              ? `ntri facilities - ${this.$i18n.t(
                  `${this.$route.name.charAt(0).toLowerCase()}${this.$route.name.slice(
                    1
                  )}Page.title`
                )}`
              : 'ntri facilities';
        }
      },
    },
    currentUser: {
      deep: true,
      handler(updatedData, oldData) {
        if (
          this.isDefined(oldData) &&
          Object.keys(oldData).length !== 0 &&
          updatedData.revokedUserToken &&
          updatedData.revokedUserToken !== oldData.revokedUserToken
        ) {
          this.signOut();
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import './scss/_settings.scss';
@import './scss/components/onboarding.scss';

.v-toolbar {
  .logo__container {
    svg {
      .a {
        fill: #282e7c;
      }
      .b {
        fill: url(#a);
      }
      .c {
        fill: #74e2ff;
      }
      .d {
        fill: #ffbc00;
      }
    }
  }
}
</style>
