import Vue from 'vue';
import FirebaseHelper from '@ntri/ntri-components/src/firebase_config';

// Enable all imports to make emulator work.
// add connectFirestoreEmulator to import { enableIndexedDbPersistence} from 'firebase/firestore';
import { enableIndexedDbPersistence } from 'firebase/firestore';
import { getMessaging } from 'firebase/messaging';
// import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
// import { connectAuthEmulator } from 'firebase/auth';
// import { connectStorageEmulator } from 'firebase/storage';

// connectFirestoreEmulator(FirebaseHelper.getDb(), 'localhost', 8070);
// connectFunctionsEmulator(getFunctions(FirebaseHelper.app), 'localhost', 5001);
// connectAuthEmulator(FirebaseHelper.getFirebaseAuth(), 'http://localhost:8071');
// connectStorageEmulator(FirebaseHelper.getStorageRef(), 'localhost', 9199);

// alias
// Vue.prototype.$analytics = firebase.analytics();
FirebaseHelper.initApp(true);
Vue.prototype.$messaging = getMessaging(FirebaseHelper.app);

enableIndexedDbPersistence(FirebaseHelper.getDb())
  .then(() => {
    console.log('enabled persistence');
  })
  .catch((err) => {
    console.log(err.code);

    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
