import { accountTypes } from '@ntri/ntri-components/src/helpers/accountTypes';
import store from '../store/index';

export default {
  path: '/:lang?/personen',
  component: () =>
    import(/* webpackChunkName: "people" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
  beforeEnter(to, from, next) {
    const isAllowed = (accountType) =>
      [
        accountTypes.administrator,
        accountTypes.hrManager,
        accountTypes.facilityStaff,
        accountTypes.facilityManager,
        accountTypes.employee,
        accountTypes.technicalDepartment,
        accountTypes.organisationManager,
      ].includes(accountType);
    // nav guard, prevents user from accessing certain pages
    if (!store.getters.g_accountType) {
      store.watch(
        () => store.getters.g_accountType,
        (accountType) => {
          if (isAllowed(accountType)) {
            next();
          } else if (accountType === 'visitor') {
            next('/persoonlijke-instellingen/');
          } else {
            next(false);
            console.info('Route denied');
          }
        }
      );
    } else if (isAllowed(store.getters.g_accountType)) {
      next();
    } else if (store.getters.g_accountType === 'visitor') {
      next('/persoonlijke-instellingen/profiel');
    } else {
      next(false);
      console.info('Route denied');
    }
  },
  children: [
    {
      path: '/',
      name: 'People',
      component: () =>
        import(
          /* webpackChunkName: "people" */ '@ntri/ntri-components/src/views/people/ListPeople'
        ),
    },
    {
      path: '/:lang?/personen/:id',
      name: 'Person',
      component: () => import(/* webpackChunkName: "people" */ '../views/people/Person.vue'),
    },
  ],
};
