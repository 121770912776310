import { render, staticRenderFns } from "./Ideal.vue?vue&type=template&id=9733a600&scoped=true&"
var script = {}
import style0 from "./Ideal.vue?vue&type=style&index=0&id=9733a600&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9733a600",
  null
  
)

export default component.exports