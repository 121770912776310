import store from '../store/index';

export default {
  path: '/:lang?/signup',
  meta: { optionalAuth: true },
  component: () => import(/* webpackChunkName: "signup" */ '../views/signup/SignUp.vue'),
  children: [
    {
      path: '/',
      name: 'Options',
      meta: { signup: true },
      component: () => import(/* webpackChunkName: "signup" */ '../views/signup/Options.vue'),
    },
    {
      path: '/:lang?/signup/jouw-gegevens',
      name: 'PersonData',
      meta: { signup: true },
      component: () => import(/* webpackChunkName: "signup" */ '../views/signup/PersonData.vue'),
      beforeEnter(to, from, next) {
        if (store.getters.g_signupPlan) {
          next();
        } else {
          next('/signup');
          console.info('Data not present');
        }
      },
    },
    {
      path: '/:lang?/signup/organisatie',
      name: 'OrganisationData',
      meta: { signup: true },
      component: () => import(/* webpackChunkName: "signup" */ '../views/signup/OrganisationData.vue'),
      beforeEnter(to, from, next) {
        if (store.getters.g_signupPersonName
            && store.getters.g_signupPersonEmail
            && store.getters.g_signupPersonPhone
            && store.getters.g_signupPlan) {
          next();
        } else {
          next('/signup');
          console.info('Data not present');
        }
      },
    },
    {
      path: '/:lang?/signup/betaling',
      name: 'Payment',
      meta: { signup: true, optionalAuth: true },
      component: () => import(/* webpackChunkName: "signup" */ '../views/signup/Payment.vue'),
    },
    {
      path: '/:lang?/signup/klaar',
      name: 'Ready',
      meta: { signup: true, optionalAuth: true },
      component: () => import(/* webpackChunkName: "signup" */ '../views/signup/Ready.vue'),
    },
  ],
};
