import Vue from 'vue';
import LangRouter from 'vue-lang-router'; // vue language router instead of vue router
import FirebaseHelper from '@ntri/ntri-components/src/firebase_config';
import translations from '@ntri/ntri-components/src/lang/translations';
import localizedURLs from '../lang/localized-urls';
import store from '../store/index';
import IncidentsRouter from './IncidentsRouter';
import OrganisationsRouter from './OrganisationsRouter';
import PeopleRouter from './PeopleRouter';
import ReportsRouter from './ReportsRouter';
import SettingsRouter from './SettingsRouter';
import SignupRouter from './SignupRouter';
import VisitorsRouter from './VisitorsRouter';
import InfoRouter from './InfoRouter';

Vue.use(LangRouter, {
  defaultLanguage: 'nl',
  translations,
  localizedURLs,
});

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { optionalAuth: true },
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "login" */ '@ntri/ntri-components/src/views/LoginView.vue'),
  },
  {
    path: '/:lang?/wachtwoord-aanmaken',
    name: 'setPassword',
    meta: { optionalAuth: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '@ntri/ntri-components/src/views/LoginView.vue'),
  },
  {
    path: '/:lang?/wachtwoord-resetten',
    name: 'resetPassword',
    meta: { optionalAuth: true },
    component: () =>
      import(/* webpackChunkName: "login" */ '@ntri/ntri-components/src/views/LoginView.vue'),
  },
  SignupRouter,
  {
    path: '/',
    redirect: '/personen',
  },
  SettingsRouter,
  {
    path: '/:lang?/persoonlijke-instellingen',
    name: 'PersonalSettings',
    meta: { personalSettings: true },
    component: () =>
      import(
        /* webpackChunkName: "usersettings" */ '@ntri/ntri-components/src/views/MainRoute.vue'
      ),
    beforeEnter(to, from, next) {
      // nav guard, prevents user from accessing certain pages
      if (to.name !== 'PersonalSettings') {
        next();
      } else {
        next({ path: '/persoonlijke-instellingen/profiel' });
        console.info('Route denied');
      }
    },
    children: [
      {
        path: '/:lang?/persoonlijke-instellingen/profiel',
        name: 'Profile',
        meta: { personalSettings: true },
        component: () =>
          import(/* webpackChunkName: "usersettings" */ '../views/people/Person.vue'),
      },
      {
        path: '/:lang?/persoonlijke-instellingen/account-instellingen',
        name: 'AccountSettings',
        meta: { personalSettings: true },
        component: () =>
          import(/* webpackChunkName: "usersettings" */ '../views/profile/AccountSettings.vue'),
      },
    ],
  },
  PeopleRouter,
  OrganisationsRouter,
  VisitorsRouter,
  IncidentsRouter,
  ReportsRouter,
  ...InfoRouter,
  {
    path: '*',
    meta: { optionalAuth: true },
    component: () => import(/* webpackChunkName: "error404" */ '../views/404.vue'),
  },
];

const router = new LangRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = !to.matched.some((record) => record.meta.optionalAuth);
  FirebaseHelper.getFirebaseAuth().onAuthStateChanged((user) => {
    if (requiresAuth && !user) {
      next('/login'); // if user is unauthorised and tries to access a page that need auth
      const loginpath = window.location.search
        ? `${window.location.pathname}${window.location.search}`
        : window.location.pathname;
      next({ name: 'Login', query: { redirectBackTo: loginpath } });
    } else if (user && to.query.redirectBackTo) {
      next(to.query.redirectBackTo); // if user accessed an url before being logged in
    } else if (
      !store.getters.g_online &&
      to.name !== 'Incidents' &&
      to.name !== 'Incident' &&
      to.name !== 'EvacuationMaps'
    ) {
      next('/meldingen'); // if the app is offline only incidents and ecav maps are accessible
    } else if (user && to.path === '/login') {
      next('/'); // if user is authorised after loggin in
    } else {
      next();
    }
  });
});

export default router;
