export default {
  path: '/:lang?/meldingen',
  component: () =>
    import(/* webpackChunkName: "incidents" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
  children: [
    {
      path: '/',
      name: 'Incidents',
      meta: { breadCrumb: 'Meldingen' },
      component: () =>
        import(
          /* webpackChunkName: "incidents" */ '@ntri/ntri-components/src/views/incidents/ListIncidents'
        ),
    },
    {
      path: '/:lang?/meldingen/:id',
      name: 'Incident',
      component: () =>
        import(/* webpackChunkName: "incidents" */ '../views/incidents/Incident.vue'),
    },
  ],
};
