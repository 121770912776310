export default [
  {
    path: '/:lang?/info',
    component: () =>
      import(/* webpackChunkName: "info" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
    children: [
      {
        path: '/',
        name: 'Info',
        component: () => import(/* webpackChunkName: "info" */ '../views/info/ListInfo'),
      },
      {
        path: '/:lang?/info/:id',
        name: 'InfoCategory',
        component: () => import(/* webpackChunkName: "info" */ '../views/info/InfoDetail.vue'),
      },
    ],
  },
  {
    path: '/:lang?/instellingen/info-instellingen',
    component: () =>
      import(/* webpackChunkName: "info" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
    children: [
      {
        path: '/',
        name: 'InfoSettings',
        meta: { settings: true },
        component: () => import(/* webpackChunkName: "info" */ '../views/info/ListInfo'),
      },
      {
        path: '/:lang?/instellingen/info-instellingen/:id',
        name: 'InfoSettingsCategory',
        meta: { settings: true },
        component: () => import(/* webpackChunkName: "info" */ '../views/info/InfoDetail.vue'),
      },
    ],
  },
];
