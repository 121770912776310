import Vue from 'vue';
import Vuex from 'vuex';
import { firestoreAction, vuexfireMutations } from 'vuexfire';
import FirebaseHelper from '@ntri/ntri-components/src/firebase_config';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showAlert: false,
    alertMessage: {},
    miniVariant: false,
    mobileDrawer: true,
    userLoggedIn: false,
    accountType: null,
    activeStep: 0,
    entityGroup: undefined,
    orgRef: '',
    organisation: {},
    person: null,
    signupPersonName: '',
    signupPersonEmail: '',
    signupPersonPhone: '',
    signupOrgName: '',
    signupOrgType: '',
    signupOrgAddress: '',
    signupOrgPostal: '',
    signupOrgCity: '',
    signupOrgCountry: '',
    signupPlan: '',
    signupPrice: '€ 29,00',
    userEmail: '',
    userId: '',
    userName: '',
    entityId: '',
    entity: undefined,
    loginError: '',
    loginTriggerAmount: 0,
    currentOrg: null,
    verificationId: null,
    online: null,
  },
  mutations: {
    userLogout(state) {
      state.userLoggedIn = false;
    },
    userLogin(state) {
      state.userLoggedIn = true;
    },
    showAlert(state, v) {
      state.showAlert = v;
    },
    alertMessage(state, v) {
      state.alertMessage = v;
    },
    accountType(state, v) {
      state.accountType = v;
    },
    miniToggle(state) {
      state.miniVariant = !state.miniVariant;
    },
    mobileToggle(state) {
      state.mobileDrawer = !state.mobileDrawer;
    },
    activeStep(state, v) {
      state.activeStep = v;
    },
    entityGroup(state, v) {
      state.entityGroup = v;
    },
    orgRef(state, v) {
      state.orgRef = v;
    },
    organisation(state, v) {
      state.organisation = v;
    },
    signupPersonName(state, v) {
      state.signupPersonName = v;
    },
    signupPersonEmail(state, v) {
      state.signupPersonEmail = v;
    },
    signupPersonPhone(state, v) {
      state.signupPersonPhone = v;
    },
    signupOrgName(state, v) {
      state.signupOrgName = v;
    },
    signupOrgType(state, v) {
      state.signupOrgType = v;
    },
    signupOrgAddress(state, v) {
      state.signupOrgAddress = v;
    },
    signupOrgPostal(state, v) {
      state.signupOrgPostal = v;
    },
    signupOrgCity(state, v) {
      state.signupOrgCity = v;
    },
    signupOrgCountry(state, v) {
      state.signupOrgCountry = v;
    },
    signupPlan(state, v) {
      state.signupPlan = v;
    },
    userEmail(state, v) {
      state.userEmail = v;
    },
    userId(state, v) {
      state.userId = v;
    },
    userName(state, v) {
      state.userName = v;
    },
    entityId(state, v) {
      state.entityId = v;
    },
    entity(state, v) {
      state.entity = v;
    },
    loginError(state, v) {
      state.loginError = v;
    },
    loginTriggerAmount(state) {
      state.loginTriggerAmount += 1;
    },
    currentOrg(state, v) {
      state.currentOrg = v;
    },
    verificationId(state, v) {
      state.verificationId = v;
    },
    online(state, v) {
      state.online = v;
    },
    ...vuexfireMutations,
  },
  getters: {
    g_showAlert(state) {
      return state.showAlert;
    },
    g_alertMessage(state) {
      return state.alertMessage;
    },
    g_accountType(state) {
      return state.accountType;
    },
    g_miniVariant(state) {
      return state.miniVariant;
    },
    g_mobileDrawer(state) {
      return state.mobileDrawer;
    },
    g_userLoggedIn(state) {
      return state.userLoggedIn;
    },
    g_activeStep(state) {
      return state.activeStep;
    },
    g_entityGroup(state) {
      return state.entityGroup;
    },
    g_orgRef(state) {
      return state.orgRef;
    },
    g_organisation(state) {
      return state.organisation;
    },
    g_person(state) {
      return state.person;
    },
    g_signupPersonEmail(state) {
      return state.signupPersonEmail;
    },
    g_signupPersonPhone(state) {
      return state.signupPersonPhone;
    },
    g_signupPersonName(state) {
      return state.signupPersonName;
    },
    g_signupOrgName(state) {
      return state.signupOrgName;
    },
    g_signupOrgType(state) {
      return state.signupOrgType;
    },
    g_signupAddress(state) {
      return state.signupOrgAddress;
    },
    g_signupPostal(state) {
      return state.signupOrgPostal;
    },
    g_signupCity(state) {
      return state.signupOrgCity;
    },
    g_signupCountry(state) {
      return state.signupOrgCountry;
    },
    g_signupPlan(state) {
      return state.signupPlan;
    },
    g_signupPrice(state) {
      return state.signupPrice;
    },
    g_userEmail(state) {
      return state.userEmail;
    },
    g_userId(state) {
      return state.userId;
    },
    g_userName(state) {
      return state.userName;
    },
    g_entityId(state) {
      return state.entityId;
    },
    g_entity(state) {
      return state.entity;
    },
    g_loginError(state) {
      return state.loginError;
    },
    g_loginTriggerAmount(state) {
      return state.loginTriggerAmount;
    },
    g_currentOrg(state) {
      return state.currentOrg;
    },
    g_verificationId(state) {
      return state.verificationId;
    },
    g_online(state) {
      return state.online;
    },
  },
  actions: {
    bindOrganisation: firestoreAction(({ bindFirestoreRef }, payload) => bindFirestoreRef('organisation', FirebaseHelper.getDb().collection('organisations').doc(payload.id))),
    bindPerson: firestoreAction(({ bindFirestoreRef }, payload) => bindFirestoreRef('person', FirebaseHelper.getDb().collection('organisations').doc(payload.orgId).collection('people')
      .doc(payload.docId))),
  },
});
