import store from '../store/index';

export default {
  path: '/:lang?/rapportages',
  name: 'Reports',
  component: () =>
    import(/* webpackChunkName: "reports" */ '@ntri/ntri-components/src/views/MainRoute.vue'),
  beforeEnter(to, from, next) {
    const isAllowed = (accountType) =>
      accountType && (accountType === 'administrator' || accountType === 'organisationManager');
    if (!store.getters.g_accountType) {
      store.watch(
        () => store.getters.g_accountType,
        (accountType) => {
          if (isAllowed(accountType)) {
            next();
          } else {
            next(false);
            console.info('Route denied');
          }
        }
      );
    } else if (isAllowed(store.getters.g_accountType)) {
      next();
    } else {
      next(false);
      console.info('Route denied');
    }
  },
  children: [
    {
      path: '/',
      name: 'Reports',
      meta: { breadCrumb: 'Rapportages' },
      component: () =>
        import(/* webpackChunkName: "reports" */ '../views/reports/ReportExport.vue'),
    },
  ],
};
